/* You can add global styles to this file, and also import other style files */
//
@import "@ag-grid-community/styles/ag-grid.css";
@import "@ag-grid-community/styles/ag-theme-balham.css";

::ng-deep .cvs-theme .mat-primary .mat-pseudo-checkbox-checked {
  background-color: black;
}

.mat-ink-bar {
  background-color: #cc0000!important;
}


.mat-button-focus-overlay {
  background: white!important;
}

ag-grid-angular .ag-body-viewport {
  max-height: unset;
  min-height: 60px;
}

ag-grid-angular .ag-root-wrapper-body  {
  height: 100%!important;
}

app-cvs-ag-grid{
  height: auto;
}

.grid-container{
  height: inherit;
}

ag-grid-angular{
  height: inherit;
}

#spinner .wrapper{
  height: inherit !important;
}

.ag-theme-balham .ag-overlay-no-rows-wrapper {
  padding-top: 43px!important;
}

#main-cvs-content {
  overflow: unset !important;
}

div.main-content main {
  height: calc(100vh - 65px);
}

.tooltip-bottom {
  text-align: left !important;
}
